import { baseResponseGraphql } from "@/graphql/baseGraphql";
import gql from "graphql-tag";


export const userRoleFields = `
id
roleUniqueId
roleName
roleDescription
rolePermissions {
    id
    permissionUniqueId
    permissionName
    permissionCode
}
`


export const createUserRole = gql`
    mutation CreateUserRoles($input: UserRolesInputObjects!) {
        createUserRoles(input: $input) {
            ${baseResponseGraphql}
            data {
                ${userRoleFields}
            }
        }
    }
`


export const updateUserRoles = gql`
    mutation UpdateUserRoles($input: UserRolesInputObjects!) {
        updateUserRoles(input: $input) {
            ${baseResponseGraphql}
            data {
                ${userRoleFields}
            }
        }
    }    
`


export const deleteUserRoles = gql`
    mutation DeleteUserRoles($roleUniqueId: String!) {
        deleteUserRoles(roleUniqueId: $roleUniqueId) {
            ${baseResponseGraphql}
        }
    }
`


export const getRoles = gql`
    query GetRoles($filtering: UserRolesFilteringInputObjects!) {
        getRoles(filtering: $filtering) {
            ${baseResponseGraphql}
            data {
                ${userRoleFields}
            }
        }
    }
`

