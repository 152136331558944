import gql from "graphql-tag";
import { baseResponseGraphql } from "@/graphql/baseGraphql";

import { userRoleFields } from "./role.graphql";
import { designationFields } from "../office_settings/designation.graphql";

export const profileFields = `
id
profileUniqueId
userFirstName
userLastName
userEmail
profilePhone
profileTitle
profilePhoto
profileIsActive
profileIsHandover
profileType
`

export const institutionFields = `
id
institutionUniqueId
institutionName
institutionShortform
institutionLogo
`;


export const getInstitution = gql`
    query GetInstitution($institutionUniqueId: String) {
        getInstitution(institutionUniqueId: $institutionUniqueId) {
            ${baseResponseGraphql}
            data {
                ${institutionFields}
            }
        }
    }
`;


export const createInstitutionMutation = gql`
    mutation CreateInstitutionMutation($input: InstitutionInputObjects!) {
        createInstitutionMutation(input: $input) {
            ${baseResponseGraphql}
            data {
                ${institutionFields}
            }
        }
    }
`


export const updateInstitutionMutation = gql`
    mutation UpdateInstitutionMutation($input: InstitutionInputObjects!) {
        updateInstitutionMutation(input: $input) {
            ${baseResponseGraphql}
            data {
                ${institutionFields}
            }
        }
    }
`

export const createInstitutionAdminMutation = gql`
mutation CreateInstitutionAdminMutation($input: InstitutionUserInputObject!) {
    createInstitutionAdminMutation(input: $input) {
        ${baseResponseGraphql}
      data {
        id
        userProfile {
          ${profileFields}
        }
        userInstitution {
          ${institutionFields}
        }
        userRoles {
          ${userRoleFields}
        }
        userDesignation {
          ${designationFields}
        }
        handoverRoles {
         ${userRoleFields}
        }
      }
    }
  }
`

export const updateInstitutionAdminMutation = gql`
mutation UpdateInstitutionAdminMutation($input: InstitutionUserInputObject!) {
  updateInstitutionAdminMutation(input: $input) {
    ${baseResponseGraphql}
      data {
        id
        userProfile {
          ${profileFields}
        }
        userInstitution {
          ${institutionFields}
        }
        userRoles {
          ${userRoleFields}
        }
        userDesignation {
          ${designationFields}
        }
        handoverRoles {
         ${userRoleFields}
        }
      }
  }
}
`