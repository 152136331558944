<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed h-[70px] z-[51] relative border-b border-white/[0.08] mt-12 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-12">
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <a href="" class="-intro-x hidden md:flex">
        <img alt="e-Ukaguzi" class="h-12" :src="appLogo" />
      </a>
      <span style="font-size: 26px" class="text-white font-bold text-lg ml-5">
        {{ shortAppName }}
      </span>
      <!-- END: Logo -->

      <template v-if="shortAppName === 'eUkaguzi'">
        <a href="" class="-intro-x hidden md:flex breadcrumb breadcrumb-light">
          <img alt="e-Ukaguzi" class="w-10" :src="imageUrl(institution?.institutionLogo)" />
        </a>
        <span style="font-size: 18px" class="text-white font-bold ml-5">
          {{ institution?.institutionShortform }}
        </span>
      </template>

      <!-- BEGIN: Breadcrumb -->
      <nav aria-label="breadcrumb" class="-intro-x h-full mr-auto">
        <ol class="breadcrumb breadcrumb-light" v-if="!hasHandedOver">
          <li class="breadcrumb-item" :class="{ 'active': !breadCrumbs?.length }">
            <router-link :to="{ name: 'AuditLanding' }">Home</router-link>
          </li>
          <template v-if="breadCrumbs.length">
            <li v-for="(crumb, index) in breadCrumbs" :key="index" class="breadcrumb-item"
              :class="{ 'active': index == (breadCrumbs.length - 1) }" aria-current="page">
              <router-link :to="crumb?.route">{{ crumb?.name }}</router-link>
            </li>
          </template>
        </ol>
      </nav>
      <!-- END: Breadcrumb -->

      <v-menu dense offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip style="margin-right: 7px; color: white" small label outlined v-bind="attrs" v-on="on">
            <v-icon small class="mr-1">mdi-translate</v-icon> {{ $root.$i18n.locale }}
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item v-for="item in $i18n.availableLocales" :key="'Local' + item" @click="$root.$i18n.locale = item">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="relative notifications intro-x">
        <div class="cursor-pointer relative" @click="showNotification = !showNotification">
          <Icon name="Bell" fill="#fff" color="#fff" size="30" class="mx-2 "></Icon>
          <span
            class="bg-red-600 absolute top-0 h-3 w-3 right-2 text-white text-[10px] p-2 flex justify-center items-center rounded-2xl">{{
          systemNotifications?.length }} </span>
        </div>
        <div v-if="showNotification" class="absolute bg-slate-100 w-[60vh] top-10 p-5 rounded-2xl shadow-md right-0">
          <h1 class="font-bold intro-x text-2xl text-primary">Notifications</h1>
          <!-- {{ systemNotifications }} -->
          <div v-if="systemNotifications?.length > 0" class="mt-4 max-h-[50vh] overflow-y-auto  p-3">
            <div v-for="(notification, index) in systemNotifications">
              <div v-if="!notification?.viewed"
                class="bg-white intro-x my-2 cursor-pointer p-3 rounded-2xl flex justify-between items-start">
                <div @click="routeToLocation(notification)" class="flex items-start">
                  <div class=" p-3 mr-2 flex items-center justify-center h-10 rounded-xl bg-primary text-white">
                    <div v-if="notification?.notificationType === 'TEST_SHEET_COMMENTS'">
                      <Icon name="FileSpreadsheet" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'AUDIT_PROGRAM_APPROVAL'">
                      <Icon name="RotateCwSquare" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'AUDIT_PROGRAM_COMMENTS'">
                      <Icon name="MessageCircle" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'DOC_REQUEST_RESPONSE'">
                      <Icon name="FileOutput" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'TEST_SHEET_APPROVAL'">
                      <Icon name="BookOpenCheck" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'FA_SHEET_COMMENTS'">
                      <Icon name="MessageSquare" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'FA_SHEET_APPROVAL'">
                      <Icon name="BookOpenCheck" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'FINDING_AUDITEE_RESPONSE'">
                      <Icon name="Scroll" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'FA_SHEET_SUBMISSION'">
                      <Icon name="FileInput" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'TEST_SHEET_SUBMISSION'">
                      <Icon name="FileUp" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'TEST_SHEET_RESUBMISSION'">
                      <Icon name="FileUp" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'FA_SHEET_RESUBMISSION'">
                      <Icon name="FileUp" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'AGREED_ACTION_PLAN'">
                      <Icon name="BookOpenCheck" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'ACKNOWLEDGEMENT_LETTER'">
                      <Icon name="FileSpreadsheet" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'DEPARTMENT_CONTACT_PERSON'">
                      <Icon name="User" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'SUBMIT_FA_APPROVAL'">
                      <Icon name="BookOpenCheck" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'FINALIZE_FA'">
                      <Icon name="BookOpenCheck" size="20"></Icon>
                    </div>
                    <div v-if="notification?.notificationType === 'APPROVE_CLOSE_CONFLICT'">
                      <Icon name="BookOpenCheck" size="20"></Icon>
                    </div>
                  </div>
                  <div class="px-2">
                    <h2 class="font-semibold text-primary text-lg">{{ notification?.title }} </h2>
                    <!-- <h3 class="text-gray-600 italic">{{ formatString(notification?.notificationType) }} </h3> -->
                    <p class="pretty">{{ notification?.message }}</p>
                    <p class="italic mt-3 text-sm text-gray-400">{{ notification?.createdBy?.userFirstName }}
                      {{ notification?.createdBy?.userLastName }} | {{ formatDateTime(notification?.createdDate) }}</p>
                  </div>
                </div>
                <div @click="dismissNotification(notification)" class="text-primary cursor-pointer">
                  <Icon name="X" size="20" class="ml-2" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mt-2  max-h-[10vh] p-3">
            <p class="bg-white p-3 w-full rounded-md">No new notifications</p>
          </div>
        </div>
      </div>
      <div class="intro-x dropdown w-8 h-8 m-3 md:m-0">

        <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
          role="button" aria-expanded="false" data-tw-toggle="dropdown">
          <img :alt="loggedUser?.userProfile?.userEmail" :src="imageUrl(loggedUser?.userProfile?.profilePhoto)"
            v-if="loggedUser?.userProfile?.profilePhoto">

        </div>
        <div class="dropdown-menu w-60">
          <ul class="dropdown-content bg-primary/80 before:block before:absolute
            before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
            <li class="p-2">
              <div class="font-medium break-all">{{ loggedUser?.userProfile?.userEmail || '' }} </div>
              <div class="text-xs text-white/60 mt-0.5 dark:text-slate-500"></div>
            </li>
            <li>
              <hr class="dropdown-divider border-white/[0.08]" />
            </li>
            <template v-for="(link, index) in $t('iterables.profile')">
              <li v-if="['Change Password'].includes(link?.name)" :key="link?.name">
                <a href="javascript:;" @click="triggerDialog()" class="dropdown-item hover:bg-white/5">
                  <icon :name="link?.icon" class="w-4 h-4 mr-2" v-if="link?.icon" /> {{ link?.name }}
                </a>
              </li>
              <li v-if="['Logout'].includes(link?.name)" :key="link?.name">
                <hr class="dropdown-divider border-white/[0.08]">
              </li>
              <li v-if="!['Change Password'].includes(link?.name)" :key="index">
                <router-link :to="link?.path || '/'" class="dropdown-item hover:bg-white/5">
                  <icon :name="link?.icon" class="w-4 h-4 mr-2" v-if="link?.icon" /> {{ link?.name }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { StorageService } from "@/services/storageService";
import { getInternalAuditNotification, dismissInternalAuditNotificationMutation } from '@/graphql/notifications/notifications.graphql'

export default {
  name: "App",
  data: () => ({
    drawer: false,
    accountMenu: [
      {
        route: 'Logout'
      }
    ],
    showNotification: false
    //
  }),
  methods: {
    triggerDialog() {
      this.$store.commit("setStoreItem", {
        entity: "passwordChangeDialog",
        data: true
      })
    },
    truncate(data, num) {
      // Check if the data is not a string or num is not a positive integer
      if (typeof data !== 'string' || !Number.isInteger(num) || num <= 0) {
        throw new Error('Invalid input. Expected a string and a positive integer for num.');
      }

      // Check if data length is less than or equal to num, return the original string
      if (data.length <= num) {
        return data;
      }

      // Truncate the string to the specified length
      return data.substring(0, num);
    },
    formatString(str) {
      // Remove underscores and split the string into an array of words
      const words = str.split('_');

      // Capitalize the first letter of each word and convert the rest to lowercase
      const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

      // Join the words into a sentence
      const formattedStr = formattedWords.join(' ');

      return formattedStr;
    },
    handleNotification(event) {
      if (!event.target.closest(".notifications")) {
        this.showNotification = false;
      }
    },
    
    async dismissNotification(data) {
      console.log(data)
      await this.$store.dispatch("dismissStoreItem", {
        entity: "systemNotifications",
        input: {
          uniqueId: data?.uniqueId
        },
        isInput: true,
        id: data?.id,
        mutation: dismissInternalAuditNotificationMutation
      })
    },
    async routeToLocation(value) {
      await this.dismissNotification(value)
      window.location.href = '/internal-audit/audit-engagements/general-objective-details/Q1/' + value?.engagementUuid
      
    },
    formatDateTime(datetimeStr) {
      const dateTime = new Date(datetimeStr);

      // Format date and time without the timezone offset
      const options = {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZoneName: 'short' // Specify the timezone to be UTC
      };

      const formattedDateTime = dateTime.toLocaleString('en-US', options);
      const currentTime = new Date()
      console.log('Date Created ' + dateTime.getTime())
      console.log('Date now' + currentTime.getTime())

      // Calculate the difference in milliseconds between the current time and the given datetime
      const diffInMs = currentTime.getTime() - dateTime.getTime();
      const diffInMins = Math.floor(diffInMs / (1000 * 60));
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      const diffInDays = Math.floor(diffInHours / 24);
      const diffInMonths = Math.floor(diffInDays / 30);
      const diffInYears = Math.floor(diffInMonths / 12);

      let agoStr;
      if (diffInYears > 0) {
        agoStr = diffInYears === 1 ? 'A year ago' : `${diffInYears} years ago`;
      } else if (diffInMonths > 0) {
        agoStr = diffInMonths === 1 ? 'A month ago' : `${diffInMonths} months ago`;
      } else if (diffInDays > 0) {
        agoStr = diffInDays === 1 ? 'A day ago' : `${diffInDays} days ago`;
      } else if (diffInHours > 0) {
        agoStr = diffInHours === 1 ? 'An hour ago' : `${diffInHours} hours ago`;
      } else if (diffInMins > 0) {
        agoStr = diffInMins === 1 ? 'A minute ago' : `${diffInMins} minutes ago`;
      }
      else {
        agoStr = 'just now';
      }

      return `${formattedDateTime} (${agoStr})`;
    }

  },
  computed: {
    currentRouterName() {
      if (this.$store.getters.getNavigationName === "auditSideBar") {
        return { name: "Internal Audit" };
      } else if (this.$store.getters.getNavigationName === "adminSideBar") {
        return { name: "System Settings" };
      } else if (this.$store.getters.getNavigationName === "followSideBar") {
        return { name: "Audit Followup" };
      } else if (this.$store.getters.getNavigationName === "contractSideBar") {
        return { name: "Contract Management" };
      } else if (this.$store.getters.getNavigationName === "trainingSideBar") {
        return { name: "Trainings" };
      } else if (this.$store.getters.getNavigationName === "reportSideBar") {
        return { name: "Reports and Analysis" };
      } else if (this.$store.getters.getNavigationName === "cagFollowupSideBar") {
        return { name: "CAG Report Followups" };
      } else if (this.$store.getters.getNavigationName === "systemConfiguration") {
        return { name: "System Configurations" };
      } else if (this.$store.getters.getNavigationName === "riskManagement") {
        return { name: "Risk Management" };
      } else if (this.$store.getters.getNavigationName === "projectInspection") {
        return { name: "Project Inspection" };
      } else {
        return { name: this.$store.getters.getNavigationName };
      }
    },
    hasHandedOver() {
      const userProfile = new StorageService().getItem('userProfile')
      return userProfile ? JSON.parse(userProfile)?.profileIsHandover : {}
    },
    breadCrumbs() {
      return this.$store.getters.getStoreItems("breadCrumbs")
    },
    institution() {
      const institution = new StorageService().getItem("userInstitution")
      return institution ? JSON.parse(institution) : {};
    },
    userProfile() {
      return this.$store.getters.getStoreItems("userProfile")
    },
    systemNotifications() {
      return this.$store.getters.getStoreItems("systemNotifications")
    }
  },
  mounted() {
    if (this.userProfile?.id) {
      this.$store.dispatch("loadStoreItem", {
        entity: "systemNotifications",
        query: getInternalAuditNotification
      });
    } else {
      console.log('not logged in')
    }
    window.addEventListener("click", this.handleNotification)
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleNotification);
  },
};
</script>
