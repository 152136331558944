import { baseResponseGraphql } from "@/graphql/baseGraphql";
import gql from "graphql-tag";
import { institutionFields } from "@/graphql/system_settings/administration_settings/institution.graphql";
import { userRoleFields } from "@/graphql/system_settings/administration_settings/role.graphql";
import { permissionFields } from "@/graphql/system_settings/role.graphql";
import { designationFields } from "../system_settings/office_settings/designation.graphql";

export const profileFields = `
id
profileUniqueId
userFirstName
userLastName
userEmail
profilePhone
profileTitle
profilePhoto
profileIsActive
profileIsHandover
profileType
`

export const userProfileFields = `
id
userProfile {
  ${profileFields}
}
userInstitution {
  ${institutionFields}
}
userRoles {
  ${userRoleFields}
}
handoverRoles {
  ${userRoleFields}
}
userDesignation {
  ${designationFields}
}
userDepartment {
  id
  departmentUnitUniqueId
  departmentUnitName
  departmentUnitResponsibility
  departmentUnitType
  departmentUsersCount
}
`

export const permissionGroupFields = `
id
permissionGroupUniqueId
permissionGroupName
permissionGroupIsGlobal
permissions {
    ${permissionFields}
}`

export const getUserProfileAndRole = gql`
query GetUserProfileAndRole {
  getUserProfileAndRole {
    ${baseResponseGraphql}
    data {
      ${userProfileFields}
    }
  }
}`
export const getInstitutionUsers = gql`
query getInstitutionUsers($filtering: UserFilteringInputObject) {
  getInstitutionUsers(filtering: $filtering) {
    ${baseResponseGraphql}
    data {
      ${userProfileFields}
    }
  }
}`

export const getSystemPermissions = gql`
query GetSystemPermissions($filtering: PermisionFilteringInputObjects!) {
    getSystemPermissions(filtering: $filtering) {
        ${baseResponseGraphql}
        data {
            ${permissionGroupFields}
        }
    }
}`
export const forgotPasswordMutation = gql`
mutation forgotPasswordMutation($userEmail: String!) {
  forgotPasswordMutation(userEmail: $userEmail) {
    ${baseResponseGraphql}
  }
}`
export const resetPasswordMutation = gql`
mutation resetPasswordMutation($input: SetPasswordFilteringInputObject!) {
  resetPasswordMutation(input: $input) {
    ${baseResponseGraphql}
  }
}`
export const changeUserPasswordMutation = gql`
mutation changeUserPasswordMutation($input: ForgortPasswordFilteringInputObject!) {
  changeUserPasswordMutation(input: $input) {
    ${baseResponseGraphql}
  }
}`
export const deactivateUserMutation = gql`
mutation deactivateUserMutation($input: ActivateDeactivateFilteringInputObject!) {
  deactivateUserMutation(input: $input) {
    ${baseResponseGraphql}
    data{
      ${userProfileFields}
    }
  }
}`
