import gql from "graphql-tag";
import { baseResponseGraphql } from "@/graphql/baseGraphql";


export const attachmentFields = `
fullFileName
fileName
`

export const uploadBase64File = gql`
    mutation UploadBase64File($input: Base64FileInputObjects!) {
        uploadBase64File(input: $input) {
            ${baseResponseGraphql}
            data {
                ${attachmentFields}
            }
        }
    }
`

