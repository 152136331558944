import { baseResponseGraphql } from "@/graphql/baseGraphql";
import gql from "graphql-tag";

export const designationFields = `    
id
designationUniqueId
designationShortForm
designationDescriptions
`


export const assigndesignationFields = ` 
    id
      userProfile {
        id
        profileUniqueId
        userFirstName
        userLastName
        userEmail
        profilePhone
        profileTitle
        profilePhoto
        profileIsActive
        profileType
      }
      userDesignation {
        id
        designationUniqueId
        designationShortForm
        designationDescriptions
      }
`







export const getDesignations = gql`query getDesignations($designationUniqueId: String) {
    getDesignations(designationUniqueId: $designationUniqueId) {
        data {
            ${designationFields}
        }
        ${baseResponseGraphql}
    }
}`
export const createInstitutionDesignationMutation = gql`mutation CreateInstitutionDesignationMutation($input: DesignationInputObjects!) {
    createInstitutionDesignationMutation(input: $input) {
        data {
            ${designationFields}
        }
        ${baseResponseGraphql}
    }
}`
export const updateInstitutionDesignationMutation = gql`mutation updateInstitutionDesignationMutation($input: DesignationInputObjects!) {
    updateInstitutionDesignationMutation(input: $input) {
        data {
            ${designationFields}
        }
        ${baseResponseGraphql}
    }
}`
export const deleteInstitutionDesignationMutation = gql`mutation deleteInstitutionDesignationMutation($designationUniqueId: String!) {
    deleteInstitutionDesignationMutation(designationUniqueId: $designationUniqueId) {
        ${baseResponseGraphql}
    }
}`



export const assignUserToDesignationMutation = gql`
    mutation AssignUserToDesignationMutation($input: DesignationUsersInputObjects!) {
        assignUserToDesignationMutation(input: $input) {
            ${baseResponseGraphql}
            data {
                ${assigndesignationFields}
            }
        }
    }
`