import { baseResponseGraphql } from "@/graphql/baseGraphql";
import gql from "graphql-tag";


export const permissionFields = `
id
permissionUniqueId
permissionName
permissionCode
`

export const userRoleFields = `
id
roleUniqueId
roleName
roleDescription
rolePermissions {
    ${permissionFields}
}
`



export const createUserRole = gql`
    mutation CreateUserRoles($input: UserRolesInputObjects!) {
        createUserRoles(input: $input) {
            ${baseResponseGraphql}
            data {
                ${userRoleFields}
            }
        }
    }
`


