import { baseResponseGraphql } from "@/graphql/baseGraphql";
import gql from "graphql-tag";


export const getInternalAuditNotification = gql`
query GetInternalAuditNotification {
    getInternalAuditNotification {
      ${baseResponseGraphql}
      data {
      id
      uniqueId
      viewed
      title
      message
      engagementUuid
      notificationType
      createdDate
      createdBy {
        id
        profileUniqueId
        userFirstName
        userLastName
        userEmail
        profilePhone
        profileTitle
        profilePhoto
        profileIsActive
        profileType
        profileIsHandover
      }
      }
    }
  }
`

export const dismissInternalAuditNotificationMutation = gql`
mutation DismissInternalAuditNotificationMutation($uniqueId: String) {
  dismissInternalAuditNotificationMutation(uniqueId: $uniqueId) {
    ${baseResponseGraphql}
  }
}
`